body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Ubuntu', sans-serif!important;
}

.MuiMenuItem-root,
.MuiListSubheader-root,
.MuiButton-root,
.MuiTab-root,
.MuiTypography-root,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiBottomNavigationAction-root,
.MuiBottomNavigationAction-label,
.SnackbarItem-base-543,
.MuiTableCell-root {
  font-family: 'Ubuntu', sans-serif!important;
  transition: background-color 0.15s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
